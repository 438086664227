import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Oferta geodezyjna w Olsztynie | Inwentaryzacje, nadzory geodezyjne</title>
    <meta
      name="description"
      content="Nasza oferta obejmuje kompleksowe usługi geodezyjne, w tym: mapy do celów projektowych, inwentaryzacje powykonawcze, geodezyjne obsługi inwestycji, nadzory geodezyjne, podziały działek, wznowienia punktów granicznych oraz specjalistyczne pomiary geodezyjne dla lotnisk i lądowisk."
    />
    <link rel="canonical" href="https://geo-partner.pl/oferta/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  .services {
    h2 {
      margin: 7rem 0 2rem;
    }
  }
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Grid = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    border-top: 1px solid #ccc;
    padding: 4rem 0 0;

    &:nth-child(1) {
      border-top: 0;
      padding-top: 0;
    }
  }

  .grid__content {
    padding: 32px 0;
    order: 1;

    h2 {
      line-height: 120%;
      margin-top: 0;
    }

    ul ul {
      margin: 10px 10px 15px;
    }
  }

  .grid__image {
    .gatsby-image-wrapper {
      max-width: 530px;
    }

    img {
      border: 1px solid #ccc;
    }
  }

  @media only screen and (min-width: 960px) {
    > li {
      grid-template-columns: repeat(2, 1fr);
      padding: 7rem 0;
    }

    .grid__content {
      order: 0;
      padding: 0 40px 0 0;
    }
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_1)}
        alt="Geodezja Olsztyn"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>
          <span>Usługi geodezyjne</span>
          <span>na terenie Olsztyna i okolic</span>
        </h1>

        <div className="services">
          <Grid>
            <li>
              <div className="grid__content">
                <h2 id="geodezja-ogolna">
                  <span>Geodezja ogólna</span>
                </h2>

                <p>Przy wielu czynnościach, które dotyczą  nieruchomości i&nbsp;jej granic powstaje dokumentacja geodezyjna. Jest ona m.&nbsp;in. podstawą zmian w&nbsp;ewidencji gruntów i&nbsp;budynków czy służy do uregulowania stanu prawnego nieruchomości. Ponadto dokumentacja geodezyjna jest nieodłącznym elementem każdego procesu inwestycyjnego. Wykorzystuje się ją w pracach projektowych, w&nbsp;trakcie realizacji inwestycji oraz wchodzi w&nbsp;skład dokumentacji odbiorowej.</p>

                <p>Na te potrzeby odpowiada nasza firma oferując:</p>

                <ul>
                  <li>pomiary sytuacyjno - wysokościowe</li>
                  <li>wykonanie map do celów projektowych</li>
                  <li>tyczenie budynku i&nbsp;przyłączy</li>
                  <li>inwentaryzacja powykonawcza</li>
                  <li>wznowienie granic</li>
                  <li>podział działki</li>
                  <li>rozgraniczenie</li>
                  <li>mapa do celów prawnych</li>
                  <li>ustalenie linii brzegowych cieków i&nbsp;jezior</li>
                  <li>uregulowania stanu prawnego</li>
                </ul>
              </div>

              <div className="grid__image">
                <GatsbyImage
                  image={getImage(data.mapa_ewidencji)}
                  alt="inwentaryzacja i nadzór geodezyjny Olsztyn"
                  loading="eager"
                />
              </div>
            </li>

            <li>
              <div className="grid__content">
                <h2>
                  <span>Geodezyjna</span>
                  <span>obsługa inwestycji</span>
                </h2>

                <p>Każda inwestycja budowlana, czy to budowa domu jednorodzinnego, budynku wielokondygnacyjnego, obiektu przemysłowego, drogi czy linii kolejowej wymaga obecności geodety. Do zadań geodety należy: założenie oraz pomiar osnowy realizacyjnej, wyznaczenie granic terenu budowy, wytyczanie głównych osi obiektów budowlanych, założenie roboczych znaków wysokościowych i&nbsp;bieżąca obsługa budowy w&nbsp;zakresie potrzeb inżyniera budowy. Końcowym etapem jest mapa powykonawcza obiektu, niezbędna do formalnego zakończenia procesu inwestycyjnego.</p>

                <p>Nasza firma wykonuje:</p>

                <ul>
                  <li>
                    wytyczenie i&nbsp;inwentaryzacja
                    <ul>
                      <li>powykonawcza budynków i&nbsp;budowli</li>
                      <li>sieci uzbrojenia terenu</li>
                    </ul>
                  </li>
                  <li>
                    obsługa inwestycji
                    <ul>
                      <li>drogowych</li>
                      <li>kolejowych</li>
                      <li>kubaturowych</li>
                    </ul>
                  </li>
                  <li>inwentaryzacje powykonawczą i&nbsp;sporządzanie map powykonawczych</li>
                  <li>pomiary suwnic i&nbsp;szybów windowych</li>
                  <li>badanie przemieszczeń i&nbsp;osiadań obiektów budowlanych</li>
                  <li>pomiar pionowości obiektów budowlanych</li>
                  <li>wykonywanie numerycznych modeli terenu DTM</li>
                  <li>obliczanie objętości</li>
                  <li>wykonywanie modeli wsadowych do maszyn budowlanych (spycharek, równiarek, koparek itp.)</li>
                  <li>geodezyjna - kartograficznej</li>
                </ul>
              </div>
              <div className="grid__image">
                <GatsbyImage
                  image={getImage(data.obsluga)}
                  alt="obsługa inwestycji drogowych"
                  loading="eager"
                />
              </div>
            </li>

            <li>
              <div className="grid__content">
                <h2>
                  <span>Nadzory geodezyjne</span>
                </h2>

                <p>W trakcie wszelkiego rodzaju inwestycji budowlanych istnieje potrzeba pełnienia nadzoru nad jej prawidłową realizacją. Zadaniem nadzoru geodezyjnego jest kontrolowanie zgodności wykonanych elementów z&nbsp;projektem, dokonywanie pomiarów kontrolnych oraz weryfikowanie prawidłowości powstającej dokumentacji geodezyjnej. Profesjonalny nadzór to sprawa kluczowa na etapie budowy obiektu, a&nbsp;wykonywany rzetelnie wpływa także na późniejszą jego bezpieczną eksploatację.</p>

                <p>Nasza firma oferuje nadzory geodezyjne nad inwestycjami:</p>

                <ul>
                  <li>drogowymi</li>
                  <li>kolejowymi </li>
                </ul>
              </div>
              <div className="grid__image">
                <GatsbyImage
                  image={getImage(data.nadzor)}
                  alt="nadzór inwestycji budowlnaych"
                  loading="eager"
                />
              </div>
            </li>

            <li>
              <div className="grid__content">
                <h2>
                  <span>Geodezyjna obsługa</span>
                  <span>lotnisk i lądowisk</span>
                </h2>

                <p>W Polsce funkcjonuje obecnie około 15 głównych portów lotniczych. Oprócz tego, znajdują się także liczne mniejsze lotniska czy lądowiska dla helikopterów. Odpowiedzialne są one m.in. za transport pasażerski, towarowy, wojskowy czy medyczny. Nasza firma posiada odpowiednie kwalifikacje i&nbsp;doświadczenie do wykonywania wszelkiego rodzaju pomiarów i&nbsp;opracowań mapowych niezbędnych do prawidłowego i&nbsp;bezpiecznego funkcjonowania lotniska czy lądowiska.</p>

                <p>Wykonujemy:</p>

                <ul>
                  <li>
                    specjalistyczne pomiary geodezyjne
                    <ul>
                      <li>infrastruktury technicznej lotnisk i&nbsp;przeszkód lotniczych</li>
                      <li>geometrii i&nbsp;spadków dróg startowych, dróg kołowania postojowych z&nbsp;weryfikacją spełniania wymagań dla certyfikacji lotniska</li>
                    </ul>
                  </li>
                  <li>dokumentację rejestracyjną lotnisk - opracowanie danych lotniczych, map do publikacji w AIP</li>
                  <li>opracowania dokumentacji ewidencyjnej lądowisk dla samolotów i&nbsp;śmigłowców</li>
                  <li>inne pomiary i&nbsp;opracowania geodezyjne</li>
                </ul>
              </div>
              <div className="grid__image">
                <GatsbyImage
                  image={getImage(data.airport)}
                  alt="geodeta Olsztyn"
                  loading="eager"
                />
              </div>
            </li>
          </Grid>

        </div>

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_1: file(relativePath: {regex: "/hero\/1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    mapa_ewidencji: file(relativePath: {regex: "/home\/mapa-ewidencji.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    airport: file(relativePath: {regex: "/home\/airport.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    nadzor: file(relativePath: {regex: "/home\/nadzor.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    obsluga: file(relativePath: {regex: "/home\/obsluga.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
  }`;

export default IndexPage;
